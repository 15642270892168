import React, { Component } from 'react'
import { is, fromJS } from 'immutable'
import { getLinkBarPageList } from '../../../utils/api/api';
 
import './index.css'


class Home extends Component {

  state = {
    pageData: [],
    name: ''
  }

  // constructor(props){
  //   super(props);
  // }

  componentWillMount() {
    // 组件初始化时调用，更新不调用，整个生命周期只有一次，可以修改state

    this.getPageDatas()
  }
  componentDidMount() {
    // 组件渲染之后调用，只调用一次
    console.log('nav-componentDidMount')
  }
  componentWillReceiveProps(nextProps) {
    // 组件初始化不调用，组件接收新的props时调用
  }
  componentWillUpdate(nextProps, nextState) {
    // 组件初始化时不调用，只有在组件将要更新时才调用，此时可以修改state
  }
  componentDidUpdate() {
    // 组件初始化时不调用，组件更新完成后调用，此时可以获取dom节点
  }
  componentWillUnmount() {
    // 组件将要卸载时调用，一些事件监听和定时器需要在此时清除
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   // 组建判断是否需要重新渲染时调用
  //   return !is(fromJS(this.props), fromJS(nextProps)) || is(fromJS(this.state), fromJS(nextState))
  // }


  getPageDatas = ()=> {
    let para = {};
    getLinkBarPageList(para).then((res) => {
      if(res){
        let { message, result, success} = res;
        if( success ){
          this.setState({
            pageData: result
          })
          return
        }
        console.log(message)
      }else{
        this.staticList()
      }
    });
  }

  renderLi = (item) => {
    return(
      <li key={'id-'+item.id}>
        <div className="block">
            <a className="alink aicon" 
                href={item.href} 
                style={ {'backgroundImage': 'url('+item.bg+')'}}
                data-title={item.title}
                target="_blank"
            >{item.name}</a>
        </div>
      </li>
    )
  }

  staticList = ()=> {
    let mockData = require('./pageData.json')
    this.setState({
      pageData: mockData
    })
  }

  render() {
    const { pageData=[] } = this.state;
    return (
      <ul className="common-list">
          {pageData && pageData.map(item=>{
              return this.renderLi(item)
          })}
      </ul>
    )
  }
}
export default Home
