import React, { Component } from 'react'
import { is, fromJS } from 'immutable'

import './index.css'

import store from '../../store'

import NavCompont from './nav'


class Home extends Component {

  constructor(props){
    super(props);
  }

  state = {
    name: '-'
  }

  componentWillMount() {
    // 组件初始化时调用，更新不调用，整个生命周期只有一次，可以修改state
    // this.setState({
    //   name: 'hello world'
    // })
    // this.setState({
    //   name: store.getState().str
    // })

    console.log('componentWillMount')

  }
  componentDidMount() {
    // 组件渲染之后调用，只调用一次
    console.log('componentDidMount')
  }
  componentWillReceiveProps(nextProps) {
    // 组件初始化不调用，组件接收新的props时调用
  }
  componentWillUpdate(nextProps, nextState) {
    // 组件初始化时不调用，只有在组件将要更新时才调用，此时可以修改state
  }
  componentDidUpdate() {
    // 组件初始化时不调用，组件更新完成后调用，此时可以获取dom节点
  }
  componentWillUnmount() {
    // 组件将要卸载时调用，一些事件监听和定时器需要在此时清除
  }
  shouldComponentUpdate(nextProps, nextState) {
    // 组建判断是否需要重新渲染时调用
    return !is(fromJS(this.props), fromJS(nextProps)) || is(fromJS(this.state), fromJS(nextState))
  }

  deal = (val) => {
    console.log(val)
    alert(val)
    this.setState({
      name: val
    })
  }

  render() {
    return (
        <div className="main">
            <div className="common-block">
                <p className="common-title"><strong>链接</strong></p>
                <NavCompont />
            </div>


            <div className="common-block">
                <p className="common-title"><strong>轻松一下</strong></p>
                <ul className="list" style={ { textAlign:'left' } }>
                    <li>
                        <a href="http://13culb.com/actgame/index.html" target="_blank">推塔游戏</a>
                    </li>
                </ul>
            </div>


            <div className="common-block">
                <p className="common-title"><strong>直播</strong></p>
                <div className="common-tips">高清直播，让看电视更加方便，让我们一起手机网页看电视吧</div>
                <div className="common-tips-moblie">温馨提示：为了保证视频流程，建议在较好网络前提下</div>
                <ul className="list">
                    <li><a className="button" href="http://sevenliao.com/cctv1.html" target="_blank">CCTV1(高清)</a></li>
                    <li><a className="button" href="http://sevenliao.com/cctv3.html" target="_blank">CCTV3(高清)</a></li>
                    <li><a className="button" href="http://sevenliao.com/cctv5.html" target="_blank">CCTV5+(高清)</a></li>
                    <li><a className="button" href="http://sevenliao.com/cctv6.html" target="_blank">CCTV6(高清)</a></li>
                
                    <li><a className="button" href="http://sevenliao.com/hunan.html" target="_blank">湖南卫视</a></li>
                    <li><a className="button" href="http://sevenliao.com/HKcaijin.html" target="_blank">香港财经</a></li>
                    <li><a className="button" href="http://sevenliao.com/KRgoodTv.html" target="_blank">韩国GoodTV</a></li>
                    <li><a className="button" href="http://sevenliao.com/USA2.html" target="_blank">美国2</a></li>
                </ul>
            </div>

            <div className="common-block">
                <p className="common-title"><strong>广告</strong></p>
                <ul className="list">
                    <li style={ { textAlign:'left' } }>
                        <h3>1.搜索关注微信公众号：sevenliao，内容更精彩</h3>
                        <img style={ { width:'120px', height:'120px', display:' inline-block' } } src="http://sevenliao.com/images/7.jpg" />
       
                        <h3><a href="http://www.sevenliao.cn/">2.前端开发技术文章</a></h3>
          
                        <h3><a href="http://13culb.com/actgame/index.html">3.13Culb游戏中心</a></h3>
         
                        <h3>4.微信小程序《大头贴纸》，给自己图片美颜一下或者加个头像呗</h3>
                        <img  style={ { width:'150px', height:'150px', display:' inline-block' } } src="http://sevenliao.com/images/dttz.jpg" />
                        
                        <h3><a href="https://juejin.cn/user/2736016885557869" target="_blank">5.稀土掘金主页</a></h3>
                    </li>
                </ul>
            </div>

            <div className="common-block">
                <p className="common-title"><strong>赞赏</strong></p>
                <ul className="list">
                    <li>
                        <h3>喜欢就这个页面，就打赏杯咖啡杯呗！</h3>
                        <img style={ { width:'220px', height:'220px', display:' inline-block' } } src="http://sevenliao.com/images/pay.jpg" />
                    </li>
                </ul>
            </div>

            <p  style={ { color:'#333333', fontSize:'12px' } }>说明： 个人学习代码，不做任何商业用途</p>

        </div>
    )
  }
}
export default Home
