import axios from 'axios';

export const userlogin = params => { return axios.post(`/login`, params).then(res => res.data).catch(err=>{console.log(err)}); };

export const getLinkBarPageList = params => { 
    return axios.post(`/linkBar/pagelist`, params).then(res => {
        if(res && res.data){
            return res.data
        }
        return null
    }).catch(err=>{console.log(err)}); 
};
