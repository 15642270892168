import axios from 'axios';
// import { Message } from 'element-ui'
// import router from './../routes'
import { BASEURL } from './storage'


//接口地址域名+端口号
axios.defaults.baseURL = BASEURL;
axios.defaults.timeout = 10000;
axios.defaults.responseType = 'json';

// http request 请求拦截器
axios.interceptors.request.use(config => {
	// 在发送请求之前做些什么
    // let pathname = location.pathname;
    let token = JSON.parse( localStorage.getItem('token') )
	if(token){
		// if(pathname != '/' &&  pathname != '/login'){
            // Authorization=`Bearer ${token}`
            // config.headers.common['token'] = localStorage.getItem('token');
        // }
        config.headers.common['Authorization'] = `Bearer ${token}`;
	}
	return config;
}, error => {
	// 对请求错误做些什么
    console.log('request err:',error)
	return Promise.reject(error);
});



// http response 响应拦截器
axios.interceptors.response.use(response => {
    // return response;

    // if (typeof(response) != 'String'&&response.data.errno !== 0 && response.config.url.indexOf('searchorderoyidornumber') < 0 && response.config.url.indexOf('upload') < 0) {
    //     response.data['data'] = response.data['data'] || {};
    //     Toast(response.data.errmsg)
    // }
    // if (typeof(response) != 'String'&&response.data.errno == 3521) {
    //     localStorage.clear();
    //     location.href = '#/login'
    // }
    // return response.status == 200 ? response.data : response;  

    if(response.status == 200){
        return response
    }else{
        console.log('接口异常')
    }
},error => {
    console.log('response err:',error)
    if (error && error.stack.indexOf('timeout') > -1) {
        // Message.error({message: '请求超时！请重试！'});
        console.log('登陆超时！请重新登陆！')
    }
    if (error.response) {
        switch (error.response.status) {
                // 返回401，清除token信息并跳转到登录页面
                case 401:
                    console.log('401 登陆超时！请重新登陆！')
                    // Message.error({message: '登陆超时！请重新登陆！'});
                    // //localStorage.removeItem('token');
                    // router.replace({
                    //     path: '/login',
                    //     //登录成功后跳入浏览的当前页面
                    //     query: {redirect: router.currentRoute.fullPath}
                    // })
        }
        // 返回接口返回的错误信息
        return Promise.reject(error.response.data);
        //return Promise.reject(error.response);
    }
});