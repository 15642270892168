import { createStore } from 'redux';
import reducer from './reducer';

const store = createStore(reducer);

export default store;



// class Provider extends Component {
//     getChildContext() { 
//         return { 
//             [storeKey]: this[storeKey], [subscriptionKey]: null
//         }
//         // storeKey 默认是'store'
//      } 
//     constructor(props, context) { 
//         super(props, context) 
//         this[storeKey] = props.store; 
//     } 
//     render() { 
//         return Children.only(this.props.children) 
//     }
// }

