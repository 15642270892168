let defaultState = {
    str: '---store test--'
}

export default (state=defaultState,action)=>{
    // if(action.type === 'change_input'){
    //     const newState = JSON.parse(JSON.stringify(state))
    //     newState.str = action.srt;
    //     return newState;
    // }
    return state;
}